import { Button, Col, Form, Input, Typography } from 'antd';
import { ReactComponent as MilaIcon } from '../../common/Icons/MilaIcon.svg';
import { oidcAuthService } from '../../app/services/auth/oidcAuthService';
import { resetApp } from '../../app/services/redux/appSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useAuth } from 'react-oidc-context';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';
import { useGetTechnicalWorkInfoQuery } from '../../app/services/api';
import { ReactComponent as TechnicalWork } from '../../assets/TechnicalWork.svg';

function Login() {
  const { isAuthenticated, isLoading } = useAuth();
  const networkId = useAppSelector((state) => state.app.networkId) || '';
  const authService = oidcAuthService;
  const { data: isTechWorkEnabled, isLoading: techWorkIsLoading } = useGetTechnicalWorkInfoQuery("", {refetchOnMountOrArgChange: true});
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLoading && isAuthenticated) networkId ? navigate('/') : navigate('/networkSelection');
    dispatch(resetApp());
  }, [isAuthenticated, isLoading]);

  const onResetPassword = async () => {
    window.location.href = await authService.resetCredentialsUrl();
  };

  const onFinish = async (values: any) => {
    await authService.signInWithLoginAndPassword(values.username, values.password);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.authFormWrapper}>
      {isLoading && <div>Loading...</div>}
      {!techWorkIsLoading && isTechWorkEnabled && <div className={styles.technicalInfoWrapper}>
        <div className={styles.technicalInfoContainer}>
          <TechnicalWork /><div className={styles.technicalInfoText}>
          Проводятся технические работы. <br />
          Часть сервисов может быть недоступна.
        </div>
        </div>
      </div>}
      {!isLoading && !isAuthenticated && (
        <div className={`${styles.formContainer} ${isTechWorkEnabled ? styles.formContainerTechnical : ''}`}>
          <Col className={styles.logo}>
            <MilaIcon />
          </Col>
          <Col className={styles.formTitle}>
            <Typography.Text>Вход в личный кабинет</Typography.Text>
          </Col>
          <Col className={styles.loginInputWrapper}>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item name="username" rules={[{ required: true, message: 'Пожалуйста введите логин!' }]}>
                <Input placeholder={'Логин'} className={styles.LoginFormInput} />
              </Form.Item>

              <div className={styles.passwordContainer}>
                <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста введите пароль!' }]}>
                  <Input
                    placeholder={'Пароль'}
                    type={showPassword ? 'text' : 'password'}
                    className={styles.LoginFormInput}
                  />
                </Form.Item>
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className={`${styles.passwordEye} ${showPassword ? styles.Open : ''}`}
                ></div>
              </div>

              <Form.Item>
                <Button htmlType="submit" className={styles.loginButton}>
                  Вход
                </Button>
              </Form.Item>

              <Col className={styles.ResetPwdLink}>
                <Button type="link" onClick={onResetPassword}>
                  Восстановить пароль
                </Button>
              </Col>
              <Col className={styles.SupportMsgLink}>
                <Typography.Link href="https://support.n3health.ru" target="_blank">
                  Написать в поддержку
                </Typography.Link>
              </Col>
            </Form>
          </Col>
        </div>
      )}
    </div>
  );
}

export default Login;
